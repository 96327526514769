import gql from 'graphql-tag'

const GET_FORM_SCORE = gql`
    query getFormScore($form_answer_id: String = "", $tenant_id: String = ""){
        formScore: getFormScore(form_answer_id: $form_answer_id, tenant_id: $tenant_id) {
            form_answer_id
            form_id
            form_set_id
            form_version
            score
            tenant_id
        }
    }
`

const GET_FORM_SCORES = gql`
    query getFormScores($formAnswerId: uuid = "", $tenantId: uuid = "") {
        formScores: form_builder_hasura_form_score(
            where: {
                form_answer_id: {
                    _eq: $formAnswerId
                },
                tenant_id: {
                    _eq: $tenantId
                },
                form: {
                    is_scorable: {
                        _eq: true
                    }
                }
            }
        ) {
            form {
                title
                form_id
                version
                is_scorable
                form_type {
                    categories
                    form_type_id
                    name
                    type
                }
            }
            score
            form_set_id
            form_version
            form_id
            form_answer_id
        }
    }
`

const GET_FORM_ANSWER_STATS = gql`query getStatsForFormAnswers(
    $tenantId: String!,
    $formAnswerIds: [String]
) {
    formAnswerStats: getStatsByFormAnswerIds(tenantId: $tenantId, formAnswerIds: $formAnswerIds) {
        code
        message
        stats
    }
}
`

const GET_ANSWERED_QUESTION_IDS = gql`
    query getAnsweredQuestionIds($tenantId: uuid = "", $formAnswerId: uuid = "") {
        questionIdsForFormAnswer: form_builder_hasura_question_ids_for_form_answer(
            where: {
                tenant_id: {
                    _eq: $tenantId
                },
                form_answer_id: {
                    _eq: $formAnswerId
                }
            }
        ) {
            question_id
        }
    }
`

const GET_FORM_SET_BY_SHORT_NAME = gql`
    query getFormSetByShortName($shortName: String = "", $parentTenantId: uuid = "") {
        formSet: form_builder_hasura_form_set(
            where: {
                _and: {
                    tenant: {
                        _or: [{
                            short_name: {
                                _eq: $shortName
                            }
                        }, {
                            tenant_id: {
                                _eq: $parentTenantId
                            }
                        }]
                    },
                    published: {
                        _eq: true
                    },
                    is_primary: {
                        _eq: true
                    }
                }
            },
            limit: 1,
            order_by: {
                tenant: {parent_tenant_id: desc_nulls_last}
            }
        ) {
            form_set_id
            form_type_id
            survey_type
        }
    }
`

const GET_FORM_ANSWER = gql`
    query getAnswer($formAnswerId: uuid!, $tenantId: uuid = null) {
        formAnswer: form_builder_hasura_form_answer(
            where: {
                _and: {
                    form_answer_id: {_eq: $formAnswerId},
                    tenant_id: {_eq: $tenantId}
                },
            }
        ) {
            answer
            created_at
            created_by
            form_answer_id
            form_set_id
            tenant_id
            status
        }
    }

`

export {
    GET_FORM_SCORE,
    GET_FORM_SCORES,
    GET_FORM_ANSWER_STATS,
    GET_ANSWERED_QUESTION_IDS,
    GET_FORM_SET_BY_SHORT_NAME,
    GET_FORM_ANSWER
}
