<template>
    <component
        v-show="show"
        :is="renderForm"
        v-model="isValid"
        ref="formInput"
        :class="buttonFormClasses"
        lazy-validation
        @click.native="editElement"
        @submit.prevent
    >
        <div
            class="text-sm text-black lg:text-base text-center mb-4"
            :class="textLabelClasses"
            v-html="label"
        ></div>
        <div :class="btnRowClasses">
            <component
                :is="renderEditableComponent"
                borderless
                class="face-scale"
                :ripple="false"
                :rules="rules"
                :class="{'disabled pointer-events-none': disabled}"
                v-model="formInput.inputValue"
            >
                <v-btn
                    v-for="(category, index) of buttons"
                    :key="`button_${index}`"
                    :value="category.value"
                    :class="{'disabled pointer-events-none': disabled}"
                >
                    <component
                        :is="getFaceSvg(category.value)"
                        class="fill-current"
                    ></component>
                </v-btn>
                <v-btn
                    :key="`button_Na`"
                    :value="0"
                    :class="{'disabled pointer-events-none': disabled}"
                >
                    <component
                        :is="getFaceSvg(0)"
                        class="fill-current"
                    ></component>
                </v-btn>
            </component>
            <div v-if="faceResultText" class="mt-4 " :class="resultTextParentClasses">
                <div class="text-center" :class="resultTextInnerClass">
                    <div class="py-2 px-4 rounded-md font-bold" :class="faceTextClass">{{ faceResultText }}</div>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
import Face1Svg from '@/assets/icons/faces/icon_face_1.svg'
import Face2Svg from '@/assets/icons/faces/icon_face_2.svg'
import Face3Svg from '@/assets/icons/faces/icon_face_3.svg'
import Face4Svg from '@/assets/icons/faces/icon_face_4.svg'
import Face5Svg from '@/assets/icons/faces/icon_face_5.svg'
import FaceNaSvg from '@/assets/icons/faces/icon_face_na.svg'
import {FormRules} from '@/components/shared/mixins/formMixins'
import {FACE_VALUE_MAPPING} from '@/modules/constants'

export default {
    name: 'FaceButtonGroup',
    components: {Face1Svg, Face2Svg, Face3Svg, Face4Svg, Face5Svg, FaceNaSvg},
    mixins: [FormRules],
    props: {
        value: {
            type: [String, Boolean, Number]
        },
        label: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        config: {
            type: Object,
            default: () => {}
        },
        editingForm: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: true
        },
        showText: {
            type: Boolean,
            default: false
        },
        displayAsRow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            renderEditableComponent: 'v-btn-toggle',
            renderForm: 'v-form',
            isValid: true,
            formInput: {inputValue: ''},
            defaultRules: [],
            defaultButtons: [],
            defaultFormClasses: 'w-full grid grid-cols-1 lg:grid-cols-4 mb-10 items-center',
            faceTextClasses: {
                '0': 'bg-primary-lightest text-primary',
                '1': 'bg-red-light text-red',
                '2': 'bg-orange-light text-orange',
                '3': 'bg-yellow-light text-yellow',
                '4': 'bg-light-green-light text-light-green',
                '5': 'bg-green-light text-green',
            }
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        editElement() {
            if (this.editingForm) this.$emit('click-triggered')
        },
        getFaceSvg(idx) {
            if (idx === 0) return `FaceNaSvg`
            return `Face${idx}Svg`
        },
    },
    computed: {
        btnGroupConfig() {
            return this.config || this.defaultConfig
        },
        buttons() {
            let buttons = this.config?.subComponents
            if (!buttons || !buttons.length) buttons = [...this.defaultButtons]

            return buttons
        },
        buttonFormClasses() {
            // if (this.displayAsRow) return 'text-center w-full items-center'
            return this.config?.buttonFormClasses || this.defaultFormClasses
        },
        faceResultText() {
            return this.value != null ? FACE_VALUE_MAPPING[this.value] : ''
        },
        faceTextClass() {
            return this.faceTextClasses[this.value] || ''
        },
        textLabelClasses() {
            return this.displayAsRow ? 'lg:col-start-2 lg:col-span-2 col-span-4' : 'lg:mb-0 col-span-1 lg:col-span-2 lg:text-left'
        },
        btnRowClasses() {
            return this.displayAsRow ? 'col-span-4 text-center' : 'col-span-1 lg:col-span-2'
        },
        resultTextParentClasses() {
            if (this.$device.mobile) return ''
            return this.displayAsRow ? 'grid grid-cols-5' : 'grid grid-cols-4'
        },
        resultTextInnerClass() {
            if (this.$device.mobile) return ''
            return this.displayAsRow ? 'col-span-1 col-start-3' : 'col-span-1 col-start-2'
        }
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            this.$emit('input', this.formInput.inputValue)
            if (this.formInput.inputValue) this.$emit('value-changed', this.formInput.inputValue)
        },
    }
}
</script>
