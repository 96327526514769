/**
 * the
 * @param args
 */
const redirectToThankYou = async (args = {}) => {
    const {category, title, tenantId, hasPhoneOrEmail, fullSurvey, formSetId, router, shortName, isKiosk, formAnswerId, routeType, emit, showPhoneAndEmail, notificationId } = args
    // setTimeout(() => {
    // TODO: remove FSI when and if we ge the new changes in to ignore a specific formSet - as we want the latest published
    const query = {c: category, t: title, ti: tenantId, hpe: hasPhoneOrEmail, fs: fullSurvey, fsi: formSetId, spe: showPhoneAndEmail, ni: notificationId}
    let name = ''
    if (isKiosk) {
        name = routeType ? `Kiosk-${routeType}-thank-you` : 'Kiosk-thank-you'
    } else {
        name = routeType ? `${routeType}-thank-you` : shortName ? 'Survey-thank-you' : 'thank-you'
    }
    const params = {formAnswerId}

    emit('route-changed')
    return await router.push({
        name: name,
        query,
        params
    })
    // }, 2000)
}

const getNextFormRouteName = (isKiosk, goToThankYou, shortName) => {
    if (goToThankYou) {
        return isKiosk ? 'Kiosk-thank-you' : shortName ? 'Survey-thank-you' : 'thank-you'
    }
    if (isKiosk) return 'Kiosk-DynamicFormView'
    return shortName ? 'Survey-DynamicFormView' : 'DynamicFormView'
}

export {
    redirectToThankYou,
    getNextFormRouteName
}
