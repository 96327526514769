<template lang="pug">
    v-form(
        ref="form"
        v-model="isValid"
        class="w-full py-4 items-center"
        @submit.prevent
    )
        .grid.grid-cols-1
        FaceButtonGroup(
            ref="form1"
            v-model="formRef.nps1"
            :tenant-name="tenantName"
            :label="labelText1"
            :config="config"
            :show-text="true"
            :display-as-row="true"
        )
        FaceButtonGroup(
            ref="form2"
            v-model="formRef.nps2"
            :tenant-name="tenantName"
            :label="labelText2"
            :config="config"
            :display-as-row="true"
        )
        FaceButtonGroup(
            ref="form3"
            v-model="formRef.nps3"
            :tenant-name="tenantName"
            :label="labelText3"
            :config="config"
            :display-as-row="true"
        )
</template>

<script>
import {ref, watchEffect} from '@vue/composition-api'
import FaceButtonGroup from '@/components/basic/FaceButtonGroup'

export default {
    name: 'NetPromoterScore3Questions',
    components: { FaceButtonGroup },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        tenantName: {
            type: String,
            default: ''
        }
    },
    setup(props, {emit}) {
        const form1 = ref(null)
        const form2 = ref(null)
        const form3 = ref(null)
        const isValid = ref(true)

        const labelText1 = ref('Access and availability')
        const labelText2 = ref('Quality of care')
        const labelText3 = ref('Customer service')
        const formRef = ref({...{
            nps1: null,
            nps2: null,
            nps3: null
        }, ...props.form})
        const config = ref({
            "subComponents": [
                {
                    "text": "",
                    "class": "",
                    "value": 1
                },
                {
                    "text": "",
                    "class": "",
                    "value": 2
                },
                {
                    "text": "",
                    "class": "",
                    "value": 3
                },
                {
                    "text": "",
                    "class": "",
                    "value": 4
                },
                {
                    "text": "",
                    "class": "",
                    "value": 5
                }
            ]
        })

        const validate = () => {
            const validList = [form1.value.validate(), form2.value.validate(), form3.value.validate()]
            // we make sure every NPS score is valid
            isValid.value = validList.every(v => v)

            return isValid.value
        }

        watchEffect(() => emit('input', {...formRef.value}))

        return {
            formRef,
            form1,
            form2,
            form3,
            labelText1,
            labelText2,
            labelText3,
            isValid,
            config,
            validate
        }
    }
}
</script>
