import {defineStore} from 'pinia'
import {computed, ref} from '@vue/composition-api'

export const usePrintFormStore = defineStore('print-form-store', () => {
    const _isPrinting = ref(false)

    const isPrinting = computed(() => _isPrinting.value)

    const updateIsPrinting = (val) => {
        _isPrinting.value = val
    }

    return { isPrinting, updateIsPrinting }
})
