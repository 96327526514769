<template>
        <v-form
            v-show="show"
            v-model="isValid"
            ref="formInput"
            class="c-editable-cell with-details"
            @submit.prevent
        >
            <label class="text-sm text-grey-10 block font-semibold mt-4 mb-2">{{label}}</label>
            <v-textarea
                v-if="!isPrinting"
                :key="uniqueId"
                outlined flat
                auto-grow
                :class="{'pl-12 pt-1': indentQuestionGroupings}"
                :disabled="disabled"
                v-bind="config.vuetifyAttrs"
                :placeholder="config.placeholder"
                v-model="formInput.inputValue"
                :items="config.items"
                :rules="rules"
                :html="formInput.inputValue"
                @change="changeHandler"
            ></v-textarea>
            <div v-else class="pl-12 pt-1">{{ formInput.inputValue }}</div>
        </v-form>
</template>

<script>
import {useTenantStylesStore} from '@/stores/useTenantStylesStore'
import {storeToRefs} from 'pinia'
import {ref} from '@vue/composition-api'
import {uuid} from 'vue-uuid'
import {usePrintFormStore} from '@/stores/usePrintFormStore'

export default {
    name: 'TextareaAndLabel',
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            default: () => {}
        },
        rules: {
            type: Array,
            default: () => []
        },
        show: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            formInput: {inputValue: ''},
            defaultRules: [],
            defaultConfig: {},
            defaultValue: '',
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        changeHandler() {
            if (!this.validate() || !this.formInput.inputValue) return
            this.$emit('input', this.formInput.inputValue)
            this.$emit('value-changed')
        }
    },
    computed: {
        radioGroupConfig() {
            return this.config || this.defaultConfig
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
    },
    setup() {
        const tenantStylesStore = useTenantStylesStore()
        const { indentQuestionGroupings } = storeToRefs(tenantStylesStore)
        const printFormStore = usePrintFormStore()
        const { isPrinting } = storeToRefs(printFormStore)
        const uniqueId = ref(uuid.v4())

        return { indentQuestionGroupings, uniqueId, isPrinting }
    }
}
</script>

