import { format, parseISO } from 'date-fns'

const SharedMethods = {
    methods: {
        getFormattedDate(dueDate) {
            return dueDate ? format(parseISO(dueDate), 'dd/MM/yy') : ''
        },
    }
}

const TableMethods = {
    methods: {
        async addNewRow(key, callback) {
            // we check to see if we have a row we are editing, if we do, save it
            if (!this.defaultNewObject) throw new Error('defaultNewObject is not defined, to use this mixin, define it on the component')
            
            if (this.activeRowId) await callback.call(this)
        
            this.sortBy = []
            this.sortDesc = [true]
        
            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempObj = {
                ...this.defaultNewObject
            }
            tempObj[key] = this.currentNewId
            this.tableDataArr.push(tempObj)
            this.expanded = [tempObj]
        },
    }
}

export {
    SharedMethods,
    TableMethods
}
