<template>
    <component
        v-show="show"
        :is="renderForm"
        v-model="isValid"
        ref="formInput"
        class="surveyRadioForm"
        lazy-validation
        @click.native="editElement"
        @submit.prevent
    >
        <div class="text-black text-base mb-4">
            {{label}}
        </div>
        <v-radio-group
            v-model="formInput.inputValue"
            :rules="rules"
            v-bind="config.vuetifyAttrs"
            :class="{'disabled pointer-events-none': disabled}"
            :row="isRow"
            @change="changeHandler"
        >
            <v-radio
                v-for="(radio, idx) in radios"
                :key="`radio-${idx}`"
                :ripple="false"
                :label="radio.text"
                :value="radio.value"
                :class="radio.class"
            ></v-radio>
        </v-radio-group>
    </component>
</template>

<script>
import {FormRules} from '@/components/shared/mixins/formMixins'

export default {
    name: 'RadioGroup',
    mixins: [FormRules],
    props: {
        value: {
            type: [String, Boolean, Number]
        },
        label: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => []
        },
        config: {
            type: Object,
            default: () => {}
        },
        editingForm: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // this is here if we want to show/hide individual components
        show: {
            type: Boolean,
            default: true
        },
        isRow: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isValid: true,
        defaultValue: null,
        formInput: {inputValue: ''},
        defaultRules: [],
        defaultRadios: [
            {
                value: 5,
                text: 'Excellent',
                class: 'excellent'
            }, {
                value: 4,
                text: '',
                class: 'veryGood'
            },{
                value: 3,
                text: '',
                class: 'good'
            },{
                value: 2,
                text: '',
                class: 'fair'
            },{
                value: 1,
                text: 'Poor',
                class: 'poor'
            }
        ],
    }),
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        editElement() {
            if (this.editingForm) this.$emit('click-triggered')
        },
        changeHandler() {
            if (!this.validate() || this.formInput.inputValue == null) return
            this.$emit('input', this.formInput.inputValue)
            this.$emit('value-changed')
        }
    },
    computed: {
        renderForm() {
            return 'v-form'
        },
        radios() {
            let radios = this.config?.subComponents
            if (!radios || !radios.length) radios = [...this.defaultRadios]

            return radios
        }
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
    },
}
</script>
